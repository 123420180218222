<template>
  <div id="gantt_chart">
    <v-dialog scrollable v-model="showAddTasksToProject" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <div class="title_left">
              <span class="text-h5">Agregar tareas al proyecto</span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-autocomplete
                    v-model="selectedElement.projectId"
                    v-bind:label="`Proyecto`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_projects"
                    hide-no-data
                    hide-selected
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeProjectInAddTasksDialog"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-autocomplete
                    v-model="selectedElement.taskIds"
                    v-bind:label="`Tareas`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_tasks"
                    :loading="isSearching['task']"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeAddTasksToProjectDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isAddingTasks"
              @click="onAddTasksToProject(selectedElement)"
              ref="btnSave"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-4">
      <v-card-title>Vision Proyecto Individual</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form_search" v-model="valid" lazy-validation>
              <v-row style="align-items: center">
                <v-col cols="12" sm="4" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.projectId"
                    v-bind:label="`Proyecto`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_projects"
                    :loading="this.isSearching['projects']"
                    hide-no-data
                    hide-selected
                    filled
                    clearable
                    required
                    :rules="requiredRules"
                    @change="onChangeProject"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-menu
                    ref="startDate_menu"
                    v-model="select_startDate_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filterMainCredential.date"
                        v-bind:label="`Fechas`"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filterMainCredential.date"
                      no-title
                      @input="select_startDate_menu = false"
                      :first-day-of-week="1"
                      :locale="this.$i18n.locale"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_brands"
                    :loading="this.isSearching['brands']"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.responsibleId"
                    v-bind:label="`Responsable`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_users"
                    :loading="this.isSearching['responsible']"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.priority"
                    v-bind:label="`Prioridad`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="PRIORITY_TYPES"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-autocomplete
                    v-model="filterMainCredential.status"
                    v-bind:label="`Estado`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="STATUS_TYPES"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
                <!-- <v-col cols="12" sm="4" md="2">
                  <v-autocomplete
                    v-model="filterMainCredential.taskType"
                    v-bind:label="`Tipo Tarea`"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :items="TASK_TYPES"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col> -->
                <v-col cols="12" sm="4" md="3">
                  <v-checkbox
                    label="solo usuarios activos"
                    v-model="isActive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="3">
                  <v-btn
                    color="blue darken-1"
                    class="mr-4"
                    @click="onClickFilter"
                  >
                    {{ $t("go") }}
                  </v-btn>
                  <v-btn color="green darken-1" @click="onShowAddTasksDialog">
                    Agregar tareas
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <div class="text-h5 text-left pl-4">Gráfico de gantt</div>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="tasks && tasks.length">
            <gantt-elastic :options="options" :tasks="tasks"></gantt-elastic>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            class="text-center text-subtitle-1"
            v-else
          >
            Sin datos
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import { logError, logInfo } from "@/utils.js";
import { downloadCSVFile } from "@/csv.js";
import moment from "moment";
import { mapGetters } from "vuex";
import download from "js-file-download";
import GanttElastic from "gantt-elastic";

export default {
  name: "GanttChart",
  props: ["projectId"],
  components: {
    GanttElastic
  },
  data: function() {
    return {
      STATUS_STYLES: {
        Completed: "rgb(51, 182, 121)",
        Pending: "#A9A9A9",
        Delayed: "red",
        Overdue: "orange"
      },
      /************************** Gantt Chart **************************/
      options: {
        taskMapping: {
          progress: "percent"
        },
        times: {
          timeZoom: 20, //*
          timePerPixel: 10,
          firstTime: null,
          lastTime: null,
          firstTaskTime: 0,
          lastTaskTime: 0,
          totalViewDurationMs: 0,
          totalViewDurationPx: 0,
          stepDuration: "day",
          steps: []
        },
        maxRows: 100,
        maxHeight: 500,
        title: {
          label: "Your project title as html (link or whatever...)",
          html: false
        },
        row: {
          height: 24
        },
        scope: {
          before: 5,
          after: 5
        },
        calendar: {
          hour: {
            display: false
          }
        },
        chart: {
          progress: {
            bar: false
          },
          expander: {
            display: true
          }
        },
        taskList: {
          expander: {
            straight: false
          },
          columns: [
            {
              id: 1,
              label: "Nombre Tarea",
              value: "name",
              width: 120,
              expander: true,
              html: true
            },
            {
              id: 2,
              label: "Fecha Inicio",
              value: "startDate",
              width: 90
            },
            {
              id: 3,
              label: "Fecha Final",
              value: "endDate",
              width: 90
            },
            {
              id: 4,
              label: "Responsable",
              value: "responsible",
              width: 90
            },
            {
              id: 5,
              label: "Dependencia",
              value: "dependency",
              width: 95
            }
          ]
        },
        locale: {
          name: "es",
          weekdays: "Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado".split(
            "_"
          ),
          weekdaysShort: "D_L_M_X_J_V_S".split("_"),
          weekdaysMin: "D_L_M_X_J_V_S".split("_"),
          months: "Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre".split(
            "_"
          ),
          monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
            "_"
          ),
          Now: "Now",
          "X-Scale": "Zoom-X",
          "Y-Scale": "Zoom-Y",
          "Task list width": "Task list",
          "Before/After": "Expand",
          "Display task list": "Task list"
        }
      },
      tasks: [],
      dynamicStyle: {},
      isAddingTasks: false,
      /******************** AddTasksToProject Dialog Options ********************/
      showAddTasksToProject: false,
      selectedElement: {},
      filtered_tasks: null,
      /******************** Search Options ******************/
      valid: true,
      filterMainCredential: {
        brandId: [],
        responsibleId: [],
        projectId: null,
        priority: [],
        status: [],
        date: null,
        isActiveUser: false
      },
      isActive: null,
      loadingInstance: null,
      filter_main_credentials_fetch_url:
        "projects/gantt/columna/getfiltermaincredentials",
      filtered_brands: null,
      filtered_projects: null,
      filtered_users: null,
      PRIORITY_TYPES: [
        { name: "Alta", id: 1 },
        { name: "Media", id: 2 },
        { name: "Baja", id: 3 }
      ],
      STATUS_TYPES: [
        { name: "A tiempo / Finalizada", id: "Completed" },
        { name: "A tiempo / Pendiente", id: "Pending" },
        { name: "Con retraso / Pendiente", id: "Delayed" },
        { name: "Con retraso / Finalizada", id: "Overdue" }
      ],
      TASK_TYPES: [
        { name: "Puntual", id: 1 },
        { name: "Recurrente", id: 2 }
      ],
      select_startDate_menu: false,
      project_list: null,

      isSearching: {},

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"])
  },
  watch: {
    projectId: function(newVal, oldVal) {
      this.filterMainCredential.projectId = newVal;
      this.onChangeProject();
      if (!oldVal && newVal) this.getDataFromApi();
    },
    closeAddTasksToProjectDialog(val) {
      val || this.closeAddTasksToProjectDialog();
    },
    async isActive(val) {
      this.filterMainCredential.isActiveUser = val;
      this.searchValues("isActive", val);
    }
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#gantt_chart"),
        name: "flow" // gird, flow, wave, bounce,
      });
      // this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    async onChangeProjectInAddTasksDialog() {
      if (this.selectedElement.projectId) {
        let fetch_url = `projects/getTasksAndList/${this.selectedElement.projectId}`;
        this.isSearching["task"] = true;
        try {
          const resp = await ApiService.post(fetch_url);
          this.filtered_tasks = resp.task_list;
          this.selectedElement.taskIds = resp.belongedTasks.map(el => el.id);
          this.isSearching["task"] = false;
        } catch (error) {
          console.log(error);
          logError(error);
          this.isSearching["task"] = false;
        }
      } else {
        this.selectedElement.taskIds = [];
      }
    },

    closeAddTasksToProjectDialog() {
      this.showAddTasksToProject = false;
      this.$nextTick(() => {
        this.selectedElement = {};
      });
    },

    onShowAddTasksDialog() {
      this.showAddTasksToProject = true;
      this.selectedElement = {
        projectId: this.filterMainCredential["projectId"],
        taskIds: []
      };
      this.onChangeProjectInAddTasksDialog();
    },

    async onAddTasksToProject(item) {
      if (this.selectedElement.projectId) {
        let body = item;
        this.isAddingTasks = true;
        const resp = await ApiService.post(`projects/addTasksToProject`, body);
        this.isAddingTasks = false;
        if (resp.success) {
          logInfo("Tareas agregadas al proyecto.");
          this.showAddTasksToProject = false;
        } else {
          logError(resp.message);
          this.showAddTasksToProject = false;
        }
      }
    },

    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      if (fieldname === "isActive") fieldname = "responsible";
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "projects") {
        this.filtered_projects = resp.data;
      } else if (fieldname === "responsible") {
        this.filtered_users = resp.data;
      }
      this.isSearching[fieldname] = false;
    },

    async onChangeProject() {
      if (this.filterMainCredential["projectId"]) {
        this.filterMainCredential["brandId"] = [];
        this.filterMainCredential["responsibleId"] = [];
        this.searchValues("responsible", "");
        this.searchValues("brands", "");
      } else {
        this.filtered_brands = null;
        this.filtered_users = null;
      }
    },

    async addItemToGantt() {
      let data_list = [];
      if (this.project_list) {
        let el = {
          id: 1,
          name: this.project_list.name,
          start: this.getStartDate(this.project_list.startDate),
          duration: this.getDuration(
            this.project_list.startDate,
            this.project_list.endDate
          ),
          startDate: this.project_list.startDate,
          endDate: this.project_list.endDate,
          percent: 100,
          responsible:
            this.project_list.user?.name +
            " " +
            this.project_list.user?.surname,
          type: "project",
          collapsed: false
        };
        data_list.push(el);
        for (let data of this.project_list.tasks) {
          let computedStatus;
          let today = moment(new Date())
            .local()
            .format("YYYY-MM-DD");
          if (data.status === "Finalizada") {
            if (data.completedDate <= data.task_endDate) {
              computedStatus = "Completed";
            } else {
              computedStatus = "Overdue";
            }
          } else {
            if (data.task_endDate < today) computedStatus = "Delayed";
            else computedStatus = "Pending";
          }
          if (
            this.filterMainCredential["status"].length &&
            !this.filterMainCredential["status"].includes(computedStatus)
          )
            continue;
          let el = {
            id: data.id + 1,
            name: data.name,
            label: data.name + " " + data.brand.name,
            start: this.getStartDate(data.task_startDate),
            duration: this.getDuration(data.task_startDate, data.task_endDate),
            startDate: data.task_startDate,
            endDate: data.task_endDate,
            responsible:
              data.tasksResponsible.name + " " + data.tasksResponsible.surname,
            percent: 100,
            parentId: 1,
            type: "task",
            collapsed: false,
            style: {
              base: {
                fill: this.STATUS_STYLES[computedStatus],
                stroke: this.STATUS_STYLES[computedStatus]
              }
            }
          };
          if (data.dependencyId) {
            el = {
              ...el,
              dependentOn: [data.dependencyId + 1],
              dependency: data.tasksDependencyChild.name
            };
          }
          data_list.push(el);
        }
      }
      this.tasks = data_list;
    },
    getDuration(start, end) {
      return new Date(end) - new Date(start) + 24 * 60 * 60 * 1000;
    },
    getStartDate(date) {
      const [year, month, day] = date.split("-").map(Number);
      const localDate = new Date(year, month - 1, day);
      return localDate.getTime();
    },
    getDate(hours) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const timeStamp = new Date(
        currentYear,
        currentMonth,
        currentDay,
        0,
        0,
        0
      ).getTime();
      return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
    },

    async onClickFilter() {
      if (this.$refs.form_search.validate()) {
        this.$emit("setSearchFilteringOptions", this.filterMainCredential);
        this.getDataFromApi();
      }
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterMainCredential)) {
        if (value !== "" && value !== null) {
          if (Array.isArray(value) && !value.length) continue;
          filterModel.push({
            columnField: key,
            filterValue: value
          });
        }
      }
      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "projects/gantt";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.project_list = resp.data;
        await this.addItemToGantt();
        this.close();
      } catch (error) {
        this.close();
      }
    },
    init() {
      this.searchValues("projects", "");
      // this.searchValues("brands", "");
      // this.searchValues("responsible", "");
    }
  },
  async mounted() {
    this.init();
  }
};
</script>
