<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ title }}</v-card-title>

      <TaskDetailDialog
        :showTaskDetail="showTaskDetail"
        @closeTask="closeTask"
        :selectedTaskId="selectedTaskId"
      ></TaskDetailDialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              :id="tableName"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tasks"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :scroll-width="1500"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltasks"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import { mapGetters } from "vuex";
import _ from "lodash";
import TaskDetailDialog from "./TaskDetailDialog.vue";

export default {
  name: "IndividualProjectTable",
  components: {
    TaskDetailDialog
  },
  props: ["projectId", "tableName", "title"],
  data: function() {
    return {
      filterable_col_list: [
        "priority",
        "date",
        "status",
        "responsibleId",
        "dependencyId",
        "taskTitle"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          let className = "";
          if (this.filterable_col_list.includes(column.field)) {
            className += "filterable-column " + column.field;
          }
          if (rowIndex === 0) {
            className += " table-header-cell-class";
          }
          return className;
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          if (row.status === "Completed")
            className += "table-body-cell-completed";
          if (row.status === "Pending") className += "table-body-cell-pending";
          if (row.status === "Delayed") className += "table-body-cell-delayed";
          if (row.status === "Overdue") className += "table-body-cell-overdue";
          return className;
        }
      },
      project: null,
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        priority: {},
        date: {},
        taskTitle: {},
        status: {
          pending: "Pending",
          delayed: "Delayed"
        },
        responsibleId: {},
        dependencyId: {}
      },
      showEmpty: false,

      totaltasks: 0,
      tasks: [],

      /********************* Task Detail ****************/
      showTaskDetail: false,
      selectedTaskId: null
    };
  },
  watch: {
    projectId: function(newVal, oldVal) {
      if (newVal) {
        this.project = newVal;
        this.getDataFromApi();
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),
    columns() {
      return [
        {
          key: "priority",
          title: "Prioridad",
          field: "priority",
          align: "left",
          fixed: "left",
          width: 100,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.priority}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["alta"]}
                    label="Alta"
                    value="Alta"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["media"]}
                    label="Media"
                    value="Media"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["baja"]}
                    label="Baja"
                    value="Baja"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "priority")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "date",
          title: "Fecha",
          field: "date",
          align: "left",
          width: 120,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.task_endDate}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["from"]}
                    value={this.filterCriteria["date"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["date"]["to"]}
                    value={this.filterCriteria["date"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "date")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "hour",
          title: "Hora",
          field: "hour",
          align: "left",
          width: 90,
          sortBy: ""
        },
        {
          key: "taskTitle",
          title: "Titulo",
          field: "taskTitle",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row?.name} {row?.brand && row?.brand.name}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["taskTitle"]}
                    apiUrl={`projects/getfilterlist/tasks`}
                    columnName={`taskTitle`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["taskTitle"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "taskTitle")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "dependencyId",
          title: "Tarea Dependiente",
          field: "dependencyId",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row?.tasksDependencyChild && row.tasksDependencyChild.name}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["dependencyId"]}
                    apiUrl={`projects/getfilterlist/tasks`}
                    columnName={`dependencyId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["dependencyId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "dependencyId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 130,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {this.getRealStatus(row.status, row.completedDate, row.endDate)}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["completed"]}
                    label="A tiempo / Finalizada"
                    value="Completed"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["pending"]}
                    label="A tiempo / Pendiente"
                    value="Pending"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["delayed"]}
                    label="Con retraso / Pendiente"
                    value="Delayed"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["overdue"]}
                    label="Con retraso / Finalizada"
                    value="Overdue"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "responsibleId",
          title: "Responsable",
          field: "responsibleId",
          align: "left",
          width: 100,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row?.tasksResponsible &&
                  row.tasksResponsible.name +
                    " " +
                    row.tasksResponsible.surname}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["responsibleId"]}
                    apiUrl={`projects/getfilterlist/tasks`}
                    columnName={`responsibleId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["responsibleId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "responsibleId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "actions",
          title: "Ir",
          field: "actions",
          align: "center",
          fixed: "right",
          width: 70,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.showTask(row)}
                >
                  mdi-plus
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    showTask(row) {
      this.showTaskDetail = true;
      this.selectedTaskId = row.id;
    },

    closeTask() {
      this.showTaskDetail = false;
      this.selectedTaskId = null;
    },

    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector(`#${this.tableName}`),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        responsibleId: _.get(item, "responsibleId"),
        brandId: _.get(item, "brandId"),
        docFile: _.get(item, "docFile"),
        task_startDate: _.get(item, "task_startDate"),
        task_endDate: _.get(item, "task_endDate"),
        static: {
          file: null,
          static: _.get(item, "static")
        }
      };
      return model;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    getRealStatus(status, completedDate, endDate) {
      let realStatus;
      let tempStatus = this.getComputedStatus(status, completedDate, endDate);
      if (tempStatus === "Completed") realStatus = "A tiempo / Finalizada";
      else if (tempStatus === "Overdue")
        realStatus = "Con retraso / Finalizada";
      else if (tempStatus === "Pending") realStatus = "A tiempo / Pendiente";
      else if (tempStatus === "Delayed") realStatus = "Con retraso / Pendiente";
      return realStatus;
    },

    getComputedStatus(status, completedDate, endDate) {
      let computedStatus;
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (status === "Finalizada") {
        if (completedDate <= endDate) computedStatus = "Completed";
        else computedStatus = "Overdue";
      } else {
        if (today <= endDate) computedStatus = "Pending";
        else computedStatus = "Delayed";
      }
      return computedStatus;
    },

    closeTaskDetail() {
      this.showEditRecurring = false;
      this.$nextTick(() => {
        this.bodyData = null;
      });
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 6);
      nextWeek = moment(nextWeek)
        .local()
        .format("YYYY-MM-DD");

      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      query_params.push("today=" + today);
      if (this.tableName === "nextDayTasks")
        query_params.push("nextWeek=" + nextWeek);

      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        `#${this.tableName} .filterable-column`
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = `projects/getTasks/${this.project}`;
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      let data_list = [];
      for (let el of resp.data) {
        let computedStatus = this.getComputedStatus(
          el.status,
          el.completedDate,
          el.endDate
        );
        if (!Object.values(this.filterCriteria["status"]).length)
          data_list.push(el);
        else if (
          Object.values(this.filterCriteria["status"]).includes(computedStatus)
        )
          data_list.push(el);
      }
      this.tasks = data_list;
      this.totaltasks = resp.total;
      if (this.tasks.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {}
};
</script>

<style>
.table-body-cell-completed {
  background-color: rgb(51, 182, 121) !important;
}
.table-body-cell-pending {
  background-color: #a9a9a9 !important;
}
.table-body-cell-overdue {
  background-color: orange !important;
}
.table-body-cell-delayed {
  background-color: rgb(213, 0, 0) !important;
}
</style>
